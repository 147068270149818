module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Capture","short_name":"Capture","start_url":"/login","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"static/favicon.png","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ad786b6b3a912c40139ae99773db37f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
